import { DialogController } from 'aurelia-dialog';
import { HttpClient, json } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';

export class Model {
    name: string = '';
    description: string = '';
}

@autoinject
export class CreateCaseDialog {
    controller: DialogController;
    model: Model;

    constructor(controller: DialogController) {
        this.controller = controller;
    }

    activate(model: Model) {
        this.model = model;
    }

    get valid(): boolean {
        return this.model.name !== '' && this.model.description !== '';
    }

    get scrollBarWidth(): number {
        return window.innerWidth - document.body.offsetWidth;
    }

    async ok() {
        this.controller.ok(this.model);
    }
}
