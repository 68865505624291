import { DialogController } from 'aurelia-dialog';
import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';

export class Model {
    id: string;

    constructor(id: string) {
        this.id = id;
    }
}

@autoinject
export class CloseDialog {
    controller: DialogController;
    model: Model;
    httpClient: HttpClient;

    constructor(controller: DialogController, httpClient: HttpClient) {
        this.controller = controller;
        this.httpClient = httpClient;
    }

    activate(model: Model) {
        this.model = model;
    }

    async ok() {
        const response: any = await this.httpClient.put(`cases/${this.model.id}/close`, JSON.stringify(this.model));

        this.controller.ok(this.model);
    }
}
