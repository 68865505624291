import { LitElement, TemplateResult, css, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '../../../pli/pli-card';
import '../../../pli/pli-text';
import '../../../pli/pli-button';
import '../../../pli/pli-user-bubble';
import '../../../pli/pli-drag-drop';

import { styles } from 'pli/styles';
import { defineHeaderItems } from 'pli/pli-table';
import { dateTimeFormatter } from 'utils/datetime-formatter';
import { nameFormatter } from 'utils/name-formatter';
import { downloadContent } from 'utils/download-content';
import { DocumentModel, DocumentPaginatedResponse } from 'schema/document/document-schema';
import { consume } from '@lit/context';
import { CustomerDetailsContext, customerDetailsContext } from 'context/details/customer-details-context';
import { confirmServiceContext } from 'context/confirm-service-context';
import { ConfirmService } from 'components/confirmation/confirm-service';
import { Task } from '@lit/task';
import { FileUpdateEvent } from '../../../pli/pli-drag-drop';
import { UserModel } from 'user-model';
import { CurrentUser, currentUserContext } from 'context/current-user-context';
import { errorServiceContext } from 'context/error-service-context';
import { ErrorService } from 'error/error-service';
import { CustomerApi, customerApiContext } from 'context/api/customer/customer-api-context';
import { UploadErrorFileResult } from 'shared/api/upload-file-result';

@customElement('customer-details-documents')
class CustomerDetailsDocuments extends LitElement {
    static styles = [styles.grid, styles.flex, styles.divider, styles.padding, css``];

    @consume({ context: customerDetailsContext })
    customerDetails: CustomerDetailsContext = null;

    @consume({ context: customerApiContext })
    customerApi?: CustomerApi;

    @consume({ context: confirmServiceContext })
    confirmService?: ConfirmService;

    @consume({ context: errorServiceContext })
    errorService?: ErrorService;

    @consume({ context: currentUserContext })
    currentUser?: CurrentUser;

    @state()
    _data: DocumentPaginatedResponse | null = null;

    _task = new Task(this, {
        task: async ([]) => {
            if (!this.customerApi || !this.customerDetails) {
                return;
            }

            this._data = await this.customerApi.getDocuments(this.customerDetails.customerId);
        },
        args: () => [] as const,
        autoRun: false,
    });

    _profile = new UserModel();

    async connectedCallback() {
        super.connectedCallback();
        this._task.run();

        if (!this.currentUser) {
            return;
        }

        this._profile = await this.currentUser.get();
    }

    downloadDocument = async (document: DocumentModel) => {
        if (!this.customerApi || !this.customerDetails) {
            return;
        }
        const content = await this.customerApi.downloadDocumentById(this.customerDetails.customerId, document.documentId);
        downloadContent(content, document.name);
    };

    removeDocument = async (document: DocumentModel) => {
        this.confirmService?.confirm(
            'Deleting document',
            `Are you sure you want to delete the file: ${document.name}. This action cannot be undone.`,
            async () => {
                if (!this.customerApi || !this.customerDetails) {
                    return;
                }
                const data = await this.customerApi.removeDocument(this.customerDetails.customerId, document.documentId);
                if (!data.documentId) return;
                this._task.run();
            },
        );
    };

    onFilesUpdate = async (event: FileUpdateEvent) => {
        const { value } = event.detail;
        for (const file of value) {
            let form = new FormData();
            form.append('file', file);

            if (!this.customerApi || !this.customerDetails) {
                return;
            }

            const result = await this.customerApi.uploadDocument(this.customerDetails.customerId, form);
            if (result.isError && result instanceof UploadErrorFileResult) {
                this.errorService?.showError(
                    `Upload error for file: ${file.name}`,
                    result.message,
                );
                return;
            }

            this._task.run();
        }
    };

    onFilesFail = (event: FileUpdateEvent) => {
        const { maximumFileSize } = event.detail;
        this.errorService?.showError(
            'File size limit reached',
            `Attached file(s) exceeds our file size limit (${maximumFileSize} MB), please try to reduce the size of your file or try a different file. `,
        );
    };

    render() {
        const { downloadDocument, removeDocument, _data, onFilesUpdate, onFilesFail } = this;
        const hasItems = Boolean(_data?.list.length);
        const headerItems = defineHeaderItems({
            Name: {
                sortField: 'Name',
                columnSpan: 3,
            },
            Date: {
                sortField: null,
                columnSpan: 3,
            },
            'Created by': {
                sortField: null,
                columnSpan: 3,
            },
            '': {
                sortField: null,
                columnSpan: 3,
            },
        });

        const renderItem = (item: DocumentModel): TemplateResult =>
            html` <tr>
                <td>
                    <pli-button variant="text" .onClick="${() => downloadDocument(item)}">
                        <pli-icon slot="icon-left" name="file-earmark-image"></pli-icon>
                        ${item.name}
                    </pli-button>
                </td>
                <td>${dateTimeFormatter(item.uploadedTime.toString())}</td>
                <td>
                    <div class="flex items-center gap-1">
                        <div>
                            <pli-user-bubble
                                userId="${item.uploadedBy.userId}"
                                firstName="${item.uploadedBy.firstName}"
                                lastName="${item.uploadedBy.lastName}"
                            ></pli-user-bubble>
                        </div>
                        <pli-text
                            >${nameFormatter({
                                firstName: item.uploadedBy.firstName,
                                lastName: item.uploadedBy.lastName,
                                userId: item.uploadedBy.userId,
                            })}</pli-text
                        >
                    </div>
                </td>
                <td>
                    <div class="flex justify-end items-center">
                        <pli-button variant="destructive" size="lg" .onClick="${() => removeDocument(item)}"
                            ><pli-icon slot="icon-left" name="trash"></pli-icon>Remove</pli-button
                        >
                    </div>
                </td>
            </tr>`;

        return html`<pli-card>
            <div class="grid-vertical gap-1">
                <div class="grid-vertical gap-1 divider-y">
                    <div class="grid-vertical gap-1">
                        <pli-text as="h2" variant="h2">Documents</pli-text>
                        <pli-text as="p"
                            >Attach, view and download any file you need to document your investigation
                        </pli-text>
                    </div>
                    ${hasItems
                        ? html`
                              <pli-table
                                  .headerItems="${headerItems}"
                                  .items="${_data?.list ?? []}"
                                  .renderTemplate="${renderItem}"
                              ></pli-table>
                          `
                        : html`<div class="py-4">
                              <div class="grid">
                                  <div class="col-span-6 col-start-4 grid-vertical gap-05">
                                      <pli-text as="p" variant="h4">No files or documents yet </pli-text>
                                      <pli-text as="p"
                                          >However, its very easy to add new ones (Maximum file size: 10 MB)
                                      </pli-text>
                                  </div>
                              </div>
                          </div>`}
                </div>
                <pli-drag-drop @filesUpdate="${onFilesUpdate}" @filesFail="${onFilesFail}"></pli-drag-drop>
            </div>
        </pli-card>`;
    }
}
