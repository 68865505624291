import { DialogController } from 'aurelia-dialog';
import { HttpClient, json } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';

export class Model {
    id: string;
    status: string;
    dismissDate: string;
    dismissReason: string;

    constructor(id: string, status: string, dismissDate: string, dismissReason: string) {
        this.id = id;
        this.status = status;
        this.dismissDate = dismissDate;
        this.dismissReason = dismissReason;
    }
}

@autoinject
export class ReinvestigateDialog {
    controller: DialogController;
    model: Model;
    httpClient: HttpClient;

    constructor(controller: DialogController, httpClient: HttpClient) {
        this.controller = controller;
        this.httpClient = httpClient;
    }

    activate(model: Model) {
        this.model = model;
    }

    async ok() {
        let response: any = await this.httpClient.put(`alerts/${this.model.id}/reinvestigate`);

        this.controller.ok(this.model);
    }

    get reason() {
        switch (this.model.status) {
            case 'FalsePositive':
                return 'False Positive';

            case 'Closed':
                return 'Closed';

            default:
                return 'Unknown';
        }
    }
}
