import { DialogController } from 'aurelia-dialog';
import { HttpClient, json } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';

export class Model {
    reason: string;
    comment: string;
    id: string;

    constructor(id: string) {
        this.id = id;
    }
}

class Reason {
    constructor(
        public id: string,
        public name: string,
    ) {}
}

@autoinject
export class DismissDialog {
    controller: DialogController;
    model: Model;
    httpClient: HttpClient;
    reasons: Reason[] = [
        new Reason('FalsePositive', 'Dismiss as False positive'),
        new Reason('Closed', 'Dismiss as Closed'),
    ];

    constructor(controller: DialogController, httpClient: HttpClient) {
        this.controller = controller;
        this.httpClient = httpClient;
    }

    activate(model: Model) {
        this.model = model;
    }

    async ok() {
        let response: any = await this.httpClient.put(`alerts/${this.model.id}/dismiss`, JSON.stringify(this.model));

        this.controller.ok(this.model);
    }
}
