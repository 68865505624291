import { DialogController } from 'aurelia-dialog';
import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import { ErrorService } from 'error/error-service';

export class AddToCaseDialogModel {
    id: string;
    cases: CaseModel[];
    addAlertDelegate: (alert: CaseModel) => void;

    constructor(id: string, cases: CaseModel[], addAlertDelegate: (alert: CaseModel) => void) {
        this.id = id;
        this.cases = cases;
        this.addAlertDelegate = addAlertDelegate;
    }
}

@autoinject
export class AddToCaseDialog {
    controller: DialogController;
    model: AddToCaseDialogModel;
    httpClient: HttpClient;
    errorService: ErrorService;

    constructor(controller: DialogController, httpClient: HttpClient, errorService: ErrorService) {
        this.controller = controller;
        this.httpClient = httpClient;
        this.errorService = errorService;
    }

    activate(model: AddToCaseDialogModel) {
        this.model = model;
    }

    select(caseModel: any): boolean {
        for (let caseModel of this.model.cases) {
            caseModel.selected = false;
        }
        caseModel.selected = true;
        const inputRadioElement = document.getElementById(`radio-${caseModel.caseId}`) as HTMLInputElement;
        inputRadioElement.checked = true;

        return true;
    }

    hasCases(): boolean {
        return this.model.cases.length > 0;
    }

    cssSizeClass(): string {
        return this.hasCases() ? 'large' : 'small';
    }

    async ok() {
        const result = this.model.cases.find((u) => u.selected);
        this.controller.ok(result);
    }
}

export class CaseModel {
    selected: boolean;
}
