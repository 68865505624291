import { DialogController } from 'aurelia-dialog';
import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import { DocumentModel } from 'document-model';

export class Model {
    caseId: string;
    documents: DocumentModel[];
    filingName: string;
    transferTypes: TransferTypeModel[];

    constructor(caseId: string, documents: DocumentModel[]) {
        this.caseId = caseId;
        this.documents = documents;
        this.transferTypes = [];
        if (this.documents && this.documents.length > 0) {
            this.transferTypes.push({
                id: TransferType.AttachedDocuments,
                name: 'Attached documents',
                description: `All (${this.documents.length}) documents attached to the case.`,
                selected: true,
            } as TransferTypeModel);
        }
    }
}

type TransferTypeModel = {
    id: TransferType;
    name: string;
    description: string;
    selected: boolean | null;
};

enum TransferType {
    CustomerInformation,
    AttachedDocuments,
}

class Reason {
    constructor(
        public id: string,
        public name: string,
    ) {}
}

@autoinject
export class ReportDialog {
    controller: DialogController;
    model: Model;
    httpClient: HttpClient;
    reasons: Reason[] = [new Reason('Resolved', 'Close as resolved'), new Reason('Reported', 'Close as reported')];

    constructor(controller: DialogController, httpClient: HttpClient) {
        this.controller = controller;
        this.httpClient = httpClient;
    }

    activate(model: Model): void {
        this.model = model;
    }

    select(shouldTransfer: boolean): void {
        shouldTransfer = !shouldTransfer;
    }

    get valid(): boolean {
        return this.model.filingName !== undefined && this.model.filingName !== '';
    }

    get hasTransferTypes(): boolean {
        return this.model.transferTypes.length > 0;
    }

    async ok(): Promise<void> {
        const transferTypes = this.model.transferTypes.filter((type) => type.selected).map((type) => type.id);
        const response = await this.httpClient.post(
            'report-filings',
            JSON.stringify({
                caseId: this.model.caseId,
                filingName: this.model.filingName,
                transferTypes: transferTypes,
            }),
        );
        const reportFilingId = (await response.json()).reportId;

        this.controller.ok(reportFilingId);
    }
}
