import { consume } from '@lit/context';
import { LitElement, TemplateResult, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { customerDetailsContext } from 'context/details/customer-details-context';
import { CustomerDetailsMapped, CustomerStatistics } from 'schema/customer-details-schema';
import { Task } from '@lit/task';
import { styles } from 'pli/styles';

import '../../../pli/pli-text';
import '../../../pli/pli-card';
import '../../../pli/pli-tab-navigation';
import '../../../pli/pli-table';
import '../../../pli/pli-status-label';
import '../../../pli/pli-user-bubble';
import '../../../pli/pli-user-bubble-list';
import '../../../pli/pli-pagination';

import { PliTableSortEvent, defineHeaderItems } from '../../../pli/pli-table';
import {
    CustomerCase,
    CustomerCaseParams,
    CustomerCaseResponse,
    customerCaseSortableFields,
    CustomerCaseSortField,
} from 'schema/customer-case-schema';

import { dateTimeFormatter } from 'utils/datetime-formatter';
import { PliTabNavigationEvent } from '../../../pli/pli-tab-navigation';
import {
    CustomerAlert,
    CustomerAlertParams,
    CustomerAlertResponse,
    customerAlertSortableFields,
    CustomerAlertSortField,
} from 'schema/customer-alert-schema';
import { customerApiContext, CustomerApi } from 'context/api/customer/customer-api-context';
import { PliPaginationChangeEvent } from '../../../pli/pli-pagination';
import { when } from 'lit/directives/when.js';
import { SortableOrder } from 'schema/pagination/pagination-schema';

@customElement('customer-details-investigations')
class CustomerDetailsInvestigations extends LitElement {
    static styles = [styles.grid, styles.flex];
    @consume({ context: customerDetailsContext, subscribe: true })
    customerDetails: CustomerDetailsMapped | null = null;

    render() {
        const { customerDetails } = this;
        return when(
            customerDetails,
            () =>
                html`<div class="grid-vertical gap-1">
                    <pli-text as="h1" variant="h2">Investigations</pli-text>
                    <customer-details-investigations-statistics
                        .customerId="${customerDetails?.customerId}"
                    ></customer-details-investigations-statistics>
                    <customer-details-investigations-overview></customer-details-investigations-overview>
                    <customer-details-investigations-cases
                        .customerId="${customerDetails?.customerId}"
                    ></customer-details-investigations-cases>
                    <customer-details-investigations-alerts
                        .customerId="${customerDetails?.customerId}"
                    ></customer-details-investigations-alerts>
                </div>`,
        );
    }
}

@customElement('customer-details-investigations-statistics')
class CustomerDetailsStatistics extends LitElement {
    static styles = [styles.grid, styles.flex, css``];

    @property()
    customerId?: CustomerDetailsMapped['customerId'];

    @consume({ context: customerApiContext, subscribe: true })
    customerApi?: CustomerApi;

    _statistics: CustomerStatistics | null = null;

    _task = new Task(this, {
        task: async ([]) => {
            if (!this.customerId) {
                return;
            }
            if (!this.customerApi) {
                return;
            }
            this._statistics = await this.customerApi.getStatsForId(this.customerId);
        },
        args: () => [] as const,
    });
    render() {
        const { _statistics } = this;
        return html` ${_statistics
            ? html`
                  <div class="grid">
                      <div class="col-span-3 grid-vertical gap-1">
                          <pli-text><strong>Total alerts</strong></pli-text>
                          <pli-text variant="h2" as="p">${_statistics.alerts}</pli-text>
                      </div>
                      <div class="col-span-3 grid-vertical gap-1">
                          <pli-text><strong>Unassigned alerts</strong></pli-text>
                          <pli-text variant="h2" as="p">${_statistics.unassignedAlerts}</pli-text>
                      </div>
                      <div class="col-span-3 grid-vertical gap-1">
                          <pli-text><strong>Total Cases created</strong></pli-text>
                          <pli-text variant="h2" as="p">${_statistics.cases}</pli-text>
                      </div>
                      <div class="col-span-3 grid-vertical gap-1">
                          <pli-text><strong>Total False positives</strong></pli-text>
                          <pli-text variant="h2" as="p">${_statistics.falsePositives}</pli-text>
                      </div>
                  </div>
              `
            : null}`;
    }
}

@customElement('customer-details-investigations-overview')
class CustomerDetailsOverview extends LitElement {
    static styles = [styles.grid, styles.flex, css``];
    render() {
        return html`<pli-card>
            <div class="grid-vertical gap-1">
                <pli-text as="h2" variant="h2">Overview</pli-text>
                <pli-text as="p" variant="h5">Latest assessment</pli-text>
                <pli-text as="p">No assessment has been made.</pli-text>
            </div>
        </pli-card>`;
    }
}

const casesNavigationItems = ['Active cases', 'Case history'] as const;
type CaseNavigationItem = (typeof casesNavigationItems)[number];

@customElement('customer-details-investigations-cases')
class CustomerDetailsInvestigationsCases extends LitElement {
    static styles = [styles.grid, styles.flex, css``];

    @property()
    customerId?: CustomerDetailsMapped['customerId'];

    @consume({ context: customerApiContext })
    customerApi?: CustomerApi;

    _data: CustomerCaseResponse | null = null;
    _defaultStatuses: CustomerCaseParams['status'] = ['Investigating', 'Reporting'];
    _historyStatuses: CustomerCaseParams['status'] = ['Reported', 'Resolved'];
    _navigationItems = casesNavigationItems;
    _items: CustomerCase[] = [];

    @state()
    _statuses: CustomerCaseParams['status'] = [...this._defaultStatuses];
    @state()
    _page = 1;
    @state()
    _total = 1;
    @state()
    _sortField: CustomerCaseSortField | null = null;
    @state()
    _sortOrder: SortableOrder | null = null;

    _task = new Task(this, {
        task: async ([page, sortField, sortOrder, statuses]) => {
            if (!this.customerApi) {
                return;
            }

            if (!this.customerId) {
                return;
            }

            this._data = await this.customerApi.getCasesForId(this.customerId, {
                page,
                status: statuses,
                sortField,
                sortOrder
            });
            this._items = [...this._data.list];
            this._total = this._data.total;
        },
        args: () => [this._page, this._sortField, this._sortOrder, this._statuses] as const,
    });

    onNavigate = (event: PliTabNavigationEvent) => {
        const current = event.detail.current as CaseNavigationItem;
        switch (current) {
            case 'Case history':
                return (this._statuses = [...this._historyStatuses]);
            case 'Active cases':
            default:
                return (this._statuses = [...this._defaultStatuses]);
        }
    };

    onSort = (event: PliTableSortEvent) => {
        const { field, order } = event.detail;
        this._sortField = field as CustomerCaseSortField;
        this._sortOrder = order;
    };

    private _paginationHandler(event: CustomEvent) {
        this._page = event.detail.page;
    }

    render() {
        const { onNavigate, onSort, _data: data, _navigationItems } = this;
        const hasItems = Boolean(data?.list?.length);
        const headerItems = defineHeaderItems({
            Id: {
                sortField: null,
                columnSpan: 1,
            },
            Name: {
                sortField: null,
                columnSpan: 2,
            },
            Status: {
                sortField: null,
                columnSpan: 3,
            },
            Created: {
                sortField: customerCaseSortableFields.enum.Created,
                columnSpan: 4,
            },
            Assigned: {
                sortField: null,
                columnSpan: 2,
            },
        });

        const renderTemplate = (item: CustomerCase): TemplateResult =>
            html` <tr>
                <td>${item.caseId}</td>
                <td>
                    <a
                        data-link="navigate"
                        href="${`customers/${item.customerId}/cases/${item.caseId}/#investigations`}"
                        >${item.name}</a
                    >
                </td>
                <td><pli-status-label variant="${item.status}"></pli-status-label></td>
                <td>${dateTimeFormatter(item.created)}</td>
                <td>
                    <pli-user-bubble-list
                        .items="${item.assigned.map((i) => ({
                            firstName: i.firstName,
                            lastName: i.lastName,
                            userId: i.userId,
                        }))}"
                    ></pli-user-bubble-list>
                </td>
            </tr>`;

        return html`<pli-card>
            <pli-tab-navigation .items="${_navigationItems}" @navigate="${onNavigate}"></pli-tab-navigation>
            <pli-pager
                .items="${this._items}"
                page="${this._page}"
                total="${this._total}"
                @page-update="${this._paginationHandler}"
                ><pli-table
                    @sort="${onSort}"
                    .headerItems="${headerItems}"
                    .items="${this._items}"
                    .renderTemplate="${renderTemplate}"
                ></pli-table
            ></pli-pager>
        </pli-card>`;
    }
}

const alertsNavigationItems = ['Active Alerts', 'Alert history'] as const;
type AlertNavigationItem = (typeof alertsNavigationItems)[number];

@customElement('customer-details-investigations-alerts')
class CustomerDetailsInvestigationsAlerts extends LitElement {
    static styles = [styles.grid, styles.flex, css``];

    @property()
    customerId?: CustomerDetailsMapped['customerId'];

    @consume({ context: customerApiContext })
    customerApi?: CustomerApi;

    data: CustomerAlertResponse | null = null;

    _navigationItems = alertsNavigationItems;
    _items: CustomerAlert[] = [];
    _defaultStatuses: CustomerAlertParams['status'] = ['Escalated', 'New', 'Opened'];
    _historyStatuses: CustomerAlertParams['status'] = ['Closed', 'FalsePositive'];

    @state()
    _statuses: CustomerAlertParams['status'] = [...this._defaultStatuses];
    @state()
    _page = 1;
    @state()
    _total = 1;
    @state()
    _sortField: CustomerAlertSortField | null = null;
    @state()
    _sortOrder: SortableOrder | null = null;

    _task = new Task(this, {
        task: async ([page, sortField, sortOrder, statuses]) => {
            if (!this.customerApi) {
                return;
            }
            if (!this.customerId) {
                return;
            }
            this.data = await this.customerApi.getAlertsForId(this.customerId, {
                page,
                status: statuses,
                sortOrder,
                sortField,                
            });
            this._items = [...this.data.list];
            this._total = this.data.total;
        },
        args: () => [this._page, this._sortField, this._sortOrder, this._statuses] as const,
    });

    onPaginationChanged = (event: PliPaginationChangeEvent) => {
        const { page } = event.detail;
        this._page = page;
    };

    onNavigate = (event: PliTabNavigationEvent) => {
        const current = event.detail.current as AlertNavigationItem;
        switch (current) {
            case 'Alert history':
                return (this._statuses = [...this._historyStatuses]);
            case 'Active Alerts':
            default:
                return (this._statuses = [...this._defaultStatuses]);
        }
    };

    onSort = (event: PliTableSortEvent) => {
        const { field, order } = event.detail;
        this._sortField = field as CustomerAlertSortField;
        this._sortOrder = order;
    };

    private _paginationHandler(event: CustomEvent) {
        this._page = event.detail.page;
    }

    render() {
        const { onNavigate, onSort, _navigationItems } = this;
        const headerItems = defineHeaderItems({
            Id: {
                sortField: null,
                columnSpan: 1,
            },
            Rule: {
                sortField: customerAlertSortableFields.enum.AlertId,
                columnSpan: 3,
            },
            Status: {
                sortField: null,
                columnSpan: 2,
            },
            Created: {
                sortField: customerAlertSortableFields.enum.Created,
                columnSpan: 4,
            },
            Assigned: {
                sortField: null,
                columnSpan: 2,
            },
        });

        const renderTemplate = (item: CustomerAlert): TemplateResult =>
            html` <tr>
                <td>${item.alertId}</td>
                <td>
                    <a
                        data-link="navigate"
                        href="${`customers/${item.customerId}/alerts/${item.alertId}/#investigations`}"
                        >${item.rule.name}</a
                    >
                </td>
                <td><pli-status-label variant="${item.status}"></pli-status-label></td>
                <td>${dateTimeFormatter(item.created)}</td>
                <td>
                    <pli-user-bubble-list
                        .items="${item.assigned.map((i) => ({
                            firstName: i.firstName,
                            lastName: i.lastName,
                            userId: i.userId,
                        }))}"
                    ></pli-user-bubble-list>
                </td>
            </tr>`;

        return html`<pli-card>
            <pli-tab-navigation .items="${_navigationItems}" @navigate="${onNavigate}"></pli-tab-navigation>
            <pli-pager
                .items="${this._items}"
                page="${this._page}"
                total="${this._total}"
                @page-update="${this._paginationHandler}"
                ><pli-table
                    @sort="${onSort}"
                    .headerItems="${headerItems}"
                    .items="${this._items}"
                    .renderTemplate="${renderTemplate}"
                ></pli-table
            ></pli-pager>
        </pli-card>`;
    }
}
