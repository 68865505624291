import { Router } from 'aurelia-router';
import { HttpClient, json } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import { TimelineItem } from 'components/timeline/timeline';
import { CreateCaseDialog, Model as CreateCaseModel } from './create-case-dialog/create-case-dialog';
import { DialogService } from 'aurelia-dialog';
import { DismissDialog, Model as DismissModel } from './dismiss-dialog/dismiss-dialog';
import { ReinvestigateDialog, Model as ReinvestigateModel } from './reinvestigate-dialog/reinvestigate-dialog';
import { AddToCaseDialog, AddToCaseDialogModel, CaseModel } from './add-to-case/add-to-case';
import { AssignService } from 'services/assign-dialog/assign-service';
import { UserModel, UserSelectModel } from 'user-model';
import { CurrentUser } from 'components/current-user/current-user';
import { AlertStatus } from 'alert-status';
import { Status } from 'case-model';
import { cleanParamsObject } from 'utils/params';

class UserRequest {
    userIds: string[];

    constructor(userIds: string[]) {
        this.userIds = userIds;
    }
}

@autoinject
export class AlertDetails {
    alerts: any[] = [{ name: 'test1' }, { name: 'test12' }, { name: 'test3' }];

    alertId: string;
    currentUser: CurrentUser;
    httpClient: HttpClient;
    customerId: string;
    alert: Alert;
    json: string;
    timelineItems: TimelineItem[] = [];
    transactions: Transaction[];
    dialogService: DialogService;
    router: Router;
    assignService: AssignService;
    users: UserModel[];
    case: Case;

    constructor(
        httpClient: HttpClient,
        dialogService: DialogService,
        router: Router,
        assignService: AssignService,
        currentUser: CurrentUser,
    ) {
        this.httpClient = httpClient;
        this.dialogService = dialogService;
        this.router = router;
        this.assignService = assignService;
        this.currentUser = currentUser;
    }

    async activate(params) {
        params = cleanParamsObject(params);
        this.alertId = params.id;
        await this.loadUsers();
        await this.loadAlert();
    }

    async loadAlert(): Promise<void> {
        await this.loadDetails();

        if (this.alert.status == AlertStatus.New) {
            await this.httpClient.put(`alerts/${this.alertId}/open`);
            this.alert.status = AlertStatus.Opened;
        }

        if (this.alert.status == AlertStatus.Escalated) {
            const response: any = await this.httpClient.get(`cases/${this.alert.caseId}`);
            const json = await response.json();

            this.case = <Case>json;
        }
    }

    async loadDetails() {
        const response: any = await this.httpClient.get(`alerts/${this.alertId}`);
        const json = await response.json();

        this.json = JSON.stringify(json, null, 2);
        this.alert = <Alert>json;
    }

    async loadTransactions() {
        try {
            const response: any = await this.httpClient.get(`alerts/${this.alertId}/transactions`);
            const json = await response.json();
            this.transactions = <Transaction[]>json;
        } catch (error) {
            //this.errorService.showErrorDialog(error.message);
        }
    }

    get isDismissed(): boolean {
        return this.alert.status == AlertStatus.Closed || this.alert.status == AlertStatus.FalsePositive;
    }

    get isEscalated(): boolean {
        return this.alert.status == AlertStatus.Escalated;
    }

    async createCase() {
        try {
            const model: CreateCaseModel = new CreateCaseModel();

            this.dialogService
                .open({
                    viewModel: CreateCaseDialog,
                    model: model,
                    lock: false,
                })
                .whenClosed(async (result) => {
                    if (!result.wasCancelled) {
                        const response: any = await this.httpClient.post(
                            `alerts/${this.alertId}/case`,
                            json(result.output),
                        );
                        const caseId = (await response.json()).caseId;
                        await this.exponentialBackoff(() => this.httpClient.get(`cases/${caseId}`));
                        this.router.navigateToRoute('customer-case-details', {
                            id: caseId,
                        });
                    }
                });
        } catch (error) {
            //this.errorService.showErrorDialog(error.message);
        }
    }

    async addToCase() {
        try {
            const response: any = await this.httpClient.get(
                `customers/${this.alert.customerId}/cases?${this.getAddToCaseStatusQuery()}`,
            );
            const casesJson = await response.json();
            const cases = <CaseModel[]>casesJson.list;

            const model: AddToCaseDialogModel = new AddToCaseDialogModel(this.alertId, cases, () => {});

            this.dialogService
                .open({ viewModel: AddToCaseDialog, model: model, lock: false })
                .whenClosed(async (result) => {
                    if (!result.wasCancelled) {
                        const caseId = result.output.caseId;
                        const body = json({ alertId: this.alertId });
                        await this.httpClient.post(`cases/${caseId}/alerts`, body);
                        await this.loadAlert();
                    }
                });
        } catch (error) {
            //this.errorService.showErrorDialog(error.message);
        }
    }

    getAddToCaseStatusQuery(): string {
        const searchParams = new URLSearchParams();
        searchParams.append('status', Status.Investigating);

        return searchParams.toString();
    }

    async dismiss() {
        try {
            const model: DismissModel = new DismissModel(this.alertId);

            this.dialogService
                .open({ viewModel: DismissDialog, model: model, lock: false })
                .whenClosed(async (response) => {
                    if (!response.wasCancelled) {
                        await this.loadDetails();
                    }
                });
        } catch (error) {
            //this.errorService.showErrorDialog(error.message);
        }
    }

    async loadUsers() {
        try {
            const response: any = await this.httpClient.get('users');
            const json = await response.json();
            const users = <UserModel[]>json.list;

            this.users = users;
        } catch (error) {
            //this.errorService.showErrorDialog(error.message);
        }
    }

    async assign(): Promise<void> {
        const selectedUsers = this.users.map((user) => {
            const userAssigned = this.alert.assigned.find((assigned) => user.userId === assigned.userId);
            const selected = userAssigned !== undefined;
            return new UserSelectModel(selected, user);
        });
        const result = await this.assignService.showAssignDialog(
            'Assign alert',
            'Assign this alert to yourself or your colleagues.',
            [...selectedUsers],
        );
        if (result.ok) {
            if (result.result.isAssignedToMe) {
                const currentUser = await this.currentUser.get();
                const userIds = new UserRequest([currentUser.userId]);
                await this.httpClient.put(`alerts/${this.alertId}/assign`, json(userIds));
                this.alert.assigned = [currentUser];
            } else {
                await this.httpClient.put(
                    `alerts/${this.alertId}/assign`,
                    json(new UserRequest(result.result.selectedUsers.map((u) => u.user.userId))),
                );
                this.alert.assigned = result.result.selectedUsers.map((selectedUser) => selectedUser.user);
            }
        }
    }

    async openReinvestigateModal() {
        try {
            const model: ReinvestigateModel = new ReinvestigateModel(
                this.alertId,
                this.alert.status,
                this.alert.dismissDate,
                this.alert.dismissReason,
            );

            this.dialogService
                .open({
                    viewModel: ReinvestigateDialog,
                    model: model,
                    lock: false,
                })
                .whenClosed(async (response) => {
                    if (!response.wasCancelled) {
                        await this.loadDetails();
                    }
                });
        } catch (error) {
            //this.errorService.showErrorDialog(error.message);
        }
    }

    async exponentialBackoff(delegate) {
        let delay = 25;
        let exp = 0;

        while (true) {
            try {
                await delegate();
                return;
            } catch (error) {
                await new Promise((f) => setTimeout(f, delay << exp));
            }

            exp += 1;
        }
    }

    get reason() {
        switch (this.alert.status) {
            case 'FalsePositive':
                return 'False Positive';

            case 'Closed':
                return 'Closed';

            default:
                return 'Unknown';
        }
    }
}

class Transaction {}

class DateRange {
    public from: string;
    public to: string;
}

export class AuditTrailItemModel {
    dateTime: string;
    action: string;
    description: string;
}

class Alert {
    public transactionType: string;
    public transactionId: string;
    public range: DateRange;
    public dismissDate: string;
    public dismissReason: string;
    public auditTrail: AuditTrailItemModel[];
    public status: AlertStatus;
    customerId: string;
    assigned;
    caseId: string;
}

class Case {
    name: string;
    description: string;
}
