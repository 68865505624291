import { DialogController } from 'aurelia-dialog';
import { HttpClient, json } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import { Status } from 'case-model';

export class Model {
    id: string;
    closedStatus: string;
    reportFilingId: number;
    reportFilingName: string;

    constructor(id: string, closedStatus: string, reportFilingId: number, reportFilingName: string) {
        this.id = id;
        this.closedStatus = closedStatus;
        this.reportFilingId = reportFilingId;
        this.reportFilingName = reportFilingName;
    }
}

@autoinject
export class OpenDialog {
    controller: DialogController;
    model: Model;
    httpClient: HttpClient;

    constructor(controller: DialogController, httpClient: HttpClient) {
        this.controller = controller;
        this.httpClient = httpClient;
    }

    activate(model: Model) {
        this.model = model;
    }

    async ok() {
        let response: any = await this.httpClient.put(`cases/${this.model.id}/open`);

        this.controller.ok(this.model);
    }

    isReportState(): boolean {
        return (
            this.model.closedStatus.toLowerCase() == Status.Reporting.toLowerCase() ||
            this.model.closedStatus.toLowerCase() == Status.Reported.toLowerCase()
        );
    }
}
