import { LitElement, TemplateResult, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { consume } from '@lit/context';
import { Task } from '@lit/task';

import '../../pli/pli-card';
import '../../pli/pli-search-form';
import '../../pli/pli-skeleton';
import '../../pli/pli-text';
import '../../pli/pli-table';
import '../../pli/pli-pagination';

import { baseStyles, gridStyles } from 'pli';
import { clientContext, HttpClient } from 'context/client-context';
import { SortOrder } from 'controllers/storage-order-controller';
import { defineHeaderItems } from '../../pli/pli-table';
import { StorageHandler } from 'storage-handler';
import { CustomerCompactResponse, CustomerCompactSortableField } from 'schema/customer-schema';
import { SortableOrder } from 'schema/pagination/pagination-schema';
import { customerApiContext, CustomerApi } from 'context/api/customer/customer-api-context';

@customElement('customers-overview')
class CustomersOverview extends LitElement {
    @consume({ context: clientContext, subscribe: true })
    @property({ attribute: false })
    client: HttpClient | undefined;

    @consume({ context: customerApiContext })
    customerApi: CustomerApi | undefined;

    static styles = [baseStyles, gridStyles];

    @state()
    _search = '';
    @state()
    _page = 1;
    @state()
    _sortOrder: SortableOrder | null = null;
    @state()
    _sortField: CustomerCompactSortableField | null = null;

    @state()
    _total = 0;

    headerItems = defineHeaderItems({
        Id: {
            sortField: null,
            columnSpan: 2,
        },
        'Customer type': {
            sortField: null,
            columnSpan: 2,
        },
        'Identification number': {
            sortField: 'Identification',
            columnSpan: 2,
        },
        Customer: {
            sortField: 'Name',
            columnSpan: 6,
        },
    });

    items: CustomerCompactResponse[] = [];

    private _task = new Task(this, {
        task: async ([page, sortField, sortOrder, search]) => {
            const data = await this.customerApi?.getAll({ page, sortField, sortOrder, search });
            this.items = data?.list ?? [];
            this._total = data?.total ?? 0;
            return data;
        },
        args: () => [this._page, this._sortField, this._sortOrder, this._search] as const,
    });

    connectedCallback(): void {
        super.connectedCallback();

        const stored = StorageHandler.getFromStorage('customers-list-order');
        if (stored !== null) {
            (this._sortOrder = stored.sortOrder as SortOrder), (this._sortField = stored.field as CustomerCompactSortableField);
        }
    }

    private _renderItem(item: CustomerCompactResponse): TemplateResult {
        return html`
            <tr>
                <td>
                    <a data-link="navigate" href="customers/${item.customerId}">
                        <strong>${item.customerId}</strong>
                    </a>
                </td>
                <td>${item.customerType ?? ''}</td>
                <td>${item.identification ?? ''}</td>
                <td>${item.name}</td>
            </tr>
        `;
    }

    private _paginationHandler(event: CustomEvent) {
        this._page = event.detail.page;
    }

    private _sort(event: CustomEvent) {
        this._sortField = event.detail.field;
        this._sortOrder = event.detail.order;
    }

    private search(event: CustomEvent) {
        this._search = event.detail.value;
        event.stopPropagation();
    }

    render() {
        return html`
            <div class="grid-vertical gap-1">
                <pli-card>
                    <div class="grid">
                        <div class="col-span-4">
                            <pli-search-form
                                @search="${this.search}"
                                placeholder="Name, Passport..."
                                value="${this._search}"
                            ></pli-search-form>
                        </div>
                    </div>
                </pli-card>
                <pli-card>
                    <div class="grid">
                        <div class="col-span-8">
                            <pli-text variant="h2" as="h2">Customers</pli-text>
                        </div>
                    </div>
                    <pli-pager
                        .items="${this.items}"
                        page="${this._page}"
                        total="${this._total}"
                        @page-update="${this._paginationHandler}"
                    >
                        <pli-table
                            @sort="${this._sort}"
                            .headerItems="${this.headerItems}"
                            .items="${this.items}"
                            .renderTemplate="${this._renderItem}"
                            sortOrderKey="customers-list-order"
                        >
                        </pli-table
                    ></pli-pager>
                </pli-card>
            </div>
        `;
    }
}
