import { DialogController } from 'aurelia-dialog';
import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import { ErrorService } from 'error/error-service';

import { AlertModel } from '../../../../alert-model';

type SelectebleAlertModel = AlertModel & {
    selected?: boolean | null | undefined;
};

export class AlertsDialogModel {
    id: string;

    constructor(id: string) {
        this.id = id;
    }
}

@autoinject
export class AlertsDialog {
    controller: DialogController;
    model: AlertsDialogModel;
    httpClient: HttpClient;
    errorService: ErrorService;

    alerts: SelectebleAlertModel[];
    selectedAlerts: SelectebleAlertModel[] = [];

    pageSize: number = 7;
    activePageNumber: number = 1;
    of: number;
    total: number;

    constructor(controller: DialogController, httpClient: HttpClient, errorService: ErrorService) {
        this.controller = controller;
        this.httpClient = httpClient;
        this.errorService = errorService;
    }

    async activate(model: AlertsDialogModel) {
        this.model = model;
        this.load(1);
    }

    async load(page: number) {
        const response: any = await this.httpClient.get(`cases/${this.model.id}/alerts/not-attached?page=${page}&take=${this.pageSize}`);
        const jsonModel = await response.json();
        const alerts = <AlertModel[]>jsonModel.list;
        const total = jsonModel.total;
        this.alerts = alerts;
        this.total = total;
        this.of = Math.ceil(total / this.pageSize);
        this.updateAlertsSelectedState();
    }

    updateAlertsSelectedState(): void {
        this.alerts.forEach((alert) => {
            const index = this.selectedAlerts.findIndex((x) => x.alertId === alert.alertId);
            alert.selected = index !== -1;
        });
    }

    select(alert: SelectebleAlertModel): boolean {
        alert.selected = !alert.selected;

        return true; // Needed for keyboard navigation
    }

    async ok(): Promise<void> {
        this.setAlertsSelectedState();
        this.controller.ok(this.selectedAlerts);
    }

    async changePage(page: number): Promise<void> {
        this.setAlertsSelectedState();
        this.load(page);
    }

    setAlertsSelectedState(): void {
        this.alerts.forEach((alert) => {
            const index = this.selectedAlerts.findIndex((x) => x.alertId === alert.alertId);
            if (index > -1) {
                if (!alert.selected) {
                    this.selectedAlerts.splice(index, 1);
                }
            } else {
                if (alert.selected) {
                    this.selectedAlerts.push(alert);
                }
            }
        });
    }
}
